/* PC */
.Menu_Fixed_PC {
    padding: 0 30px;
    font-size: 18px;
    height: 80px;
    position: relative;
    border-bottom: 1px solid white;
}

.Menu_Fixed_PC-1line{
    width: 1200px;
}

.Menu_Fixed_PC-left img {
    height: 50px;
}

.Menu_Fixed_PC-right{
    width: 360px;
}

.Menu_Fixed_PC-bottom{
    height: 150px;
    padding : 20px 0;
    font-size: 16px;
    position: absolute;
    top : 100%;
    left: 0;
    right: 0;
    background-color: var(--mainBackgroundcolor);
    color: var(--mainFontcolor);
    z-index: -1;
    transition: top 0.6s;
}

.Menu_Fixed_PC-right:hover > .Menu_Fixed_PC-bottom{
    top: 80px;
}

.Menu_Fixed_PC-bottom-group{
    width: 1200px;
}

.Menu_Fixed_PC-bottom-GMenu_Fixed{
    width: 120px;
}

/* 모바일 */
.Menu_Fixed_MB {
    height: 80px;
    padding: 0 20px;
    font-size: 24px;
}

.Menu_Fixed_MB-left img {
    height: 50px;
}

.Menu_Fixed_MB-right i{
    color : var(--mainFontcolor);
    font-size: 36px;
}

.Menu_Fixed_MB-lists {
    position : absolute;
    top: 0;
    left: auto;
    right: 0;
    width: 0;
    height: 100vh;
    overflow-y: auto;
    background: var(--mainBackgroundcolor);
    color: var(--mainFontcolor);
    transition: left 0.5s;
    transition: all 1s;
}

.Menu_Fixed_MB-lists.Output {
    width:80%;
}

.Menu_Fixed_MB-lists-Menu_Fixed{
    padding: 20px 0 20px 20px;
    position: relative;
}

.Menu_Fixed_MB-lists-Menu_Fixed i.fa-xmark{
    position: absolute;
    top: 30px; right: 30px; left: auto;
    font-size: 36px;
}

.Menu_Fixed_MB-lists-Menu_Fixed > li{
    padding: 30px 0 10px 10px;    
    font-size: 26px;
}

.Menu_Fixed_MB-lists-Menu_Fixed > li > ul > li{
    padding: 10px 0 0 4px;    
    font-size: 18px;
}

@media only screen and (max-width: 380px) {
    .Menu_Fixed_MB-left{
        zoom: 0.8;
    }

    .Menu_Fixed_MB-right i{
        font-size: 32px;
    }

    .Menu_Fixed_MB{
        padding: 10px 20px;
    } 
}

@media only screen and (max-width: 900px) {
    .Menu_Fixed_PC{
        display: none ;
    }
    .Menu_Fixed_MB {
        display: flex;
    }
}

@media only screen and (min-width: 901px) {
    .Menu_Fixed_PC{
        display: flex ;
    }

    .Menu_Fixed_MB {
        display: none;
    }
}

@media only screen and (min-width: 901px) and (max-width: 1199px) {
    .Menu_Fixed_PC-1line, .Menu_Fixed_PC-bottom-group{
        width: 900px;
    }
}

@media only screen and (min-width: 1921px) and (max-width: 2560px ) {
    .Menu_Fixed_PC-1line, .Menu_Fixed_PC-bottom-group{
        width: 1440px;
    }
}

@media only screen and (min-width: 2561px){
    .Menu_Fixed_PC-1line, .Menu_Fixed_PC-bottom-group{
        width: 1680px;
    }
}