/* pc  */
.ProductDetail_PC .Middles{
    font-size: 36px;
}

.ProductDetail_PC .Middles > p{
    position: relative;
}

.ProductDetail_PC .ListBox{
    border: 2px solid black;
    margin: 0 0 20px 0;
    padding: 10px 30px;
}

.ProductDetail_PC .CuttingBox{
    margin: 0 0 20px 0;
    padding: 10px 30px;
}

.ProductDetail_PC .CuttingBox div{
    border: 1px solid black;
    padding: 10px 20px;
}

.ProductDetail_PC .ListsHeader{
    font-size: 18px;
    padding: 8px 10px;
    border-bottom: 2px solid black;
    border-top: 2px solid black;
}

.ProductDetail_PC .Lists{
    font-size: 18px;
    padding: 20px 10px;
    border-bottom: 1px solid black;
}

.ProductDetail_PC .Description{
    font-size: 20px;
    padding: 0 0 0 10px;
}

.ProductDetail_PC .ProductLists{
    overflow: hidden;
    border: 2px solid rgba(0,0,0,0.5);
}

/* mb */
.ProductDetail_MB{
    padding : 0 30px;
}

.ProductDetail_MB .Middles{
    font-size: 36px;
}

.ProductDetail_MB .Middles > p{
    position: relative;
}

.ProductDetail_MB .ListBox{
    border: 2px solid black;
    margin: 0 0 10px 0;
    padding: 10px;
}

.ProductDetail_MB .List{
    width: calc(100vw - 60px);
    overflow-x: auto;
}

.ProductDetail_MB .ListsHeader > *{
    font-size: 18px;
    padding: 8px 10px 8px 20px;
    border-bottom: 2px solid black;
    border-top: 2px solid black;
    min-width: 200px;
}

.ProductDetail_MB .Lists > *{
    min-width: 200px;
    font-size: 16px;
    padding: 20px 10px 20px 20px;
    border-bottom: 1px solid black;
}

.ProductDetail_MB .Description{
    font-size: 14px;
    padding: 0 0 0 10px;
}

.ProductDetail_MB .ProductList{
    width: calc(100vw - 60px);
}

.ProductDetail_MB .ProductLists{
    overflow: hidden;
    border: 2px solid rgba(0,0,0,0.5);
}

@media only screen and (max-width: 900px) {
    .ProductDetail_PC{
        display: none ;
    }
    .ProductDetail_MB {
        display: flex;
    }
}
  
@media only screen and (min-width: 901px) {
    .ProductDetail_PC{
        display: flex ;
    }
    .ProductDetail_MB {
        display: none;
    }
}
