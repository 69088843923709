  /* pc */  
  .Patent_PC .Sections{
    padding-top: 50px;
    padding-bottom: 70px;
  }
  
  .Patent_PC .Sections .Title{
    font-size: 56px;
    font-weight: bold;
  }

  .Patent_PC .Sections .PatentList li{ 
    width: 210px;
  }

  .Patent_PC .Sections .PatentList .PatentName{ 
    font-size: 12px;
    font-weight: 400;
    margin : 5px 0;
  }

  .Patent_PC .Sections .PatentList .PatentAgency{ 
    font-size: 10px;
    color: var(--mainFontColor2);
    font-weight: 400;
    margin : 0 0 20px 0;
  }
  
  .Patent_PC .Sections .PatentList img{ 
    width: 240px;
    padding: 10px;
    object-fit: contain;
    border: 1px solid var(--mainFontColor2);
    margin: 0 0 10px 0;
  }

  .Patent_PC .Sections .PatentList button{ 
    padding: 5px;
    border-radius: 5px;
    background-color: var(--mainFontColor2);
    color : var(--mainFontColor3);
  }

  .Patent_PC .Sections .PatentList button i{ 
    pointer-events: none;
  }

  /* mb */
  .Patent_MB{
    padding: 0 30px;
  }
  
  .Patent_MB .Sections{
    padding-top: 50px;
    padding-bottom: 70px;
  }
  
  .Patent_MB .Sections .Title{
    font-size: 36px;
    font-weight: bold;
  }

  .Patent_MB .Sections .PatentList .PatentName{ 
    font-size: 12px;
    font-weight: 400;
    margin : 5px 0;
  }

  .Patent_MB .Sections .PatentList .PatentAgency{ 
    font-size: 10px;
    color: var(--mainFontColor2);
    font-weight: 400;
    margin : 0 0 20px 0;
  }
  
  .Patent_MB .Sections .PatentList img{ 
    padding: 10px;
    object-fit: contain;
    border: 1px solid var(--mainFontColor2);
    margin: 0 0 10px 0;
  }

  .Patent_MB .Sections .PatentList button{ 
    padding: 5px;
    border-radius: 5px;
    background-color: var(--mainFontColor2);
    color : var(--mainFontColor3);
  }

  .Patent_MB .Sections .PatentList button i{ 
    pointer-events: none;
  }
  
  .Patent .PatentUpload{
    position: fixed;
    top: 0; left: 0;
    background-color: rgba(255, 255, 255, 0.1);
    z-index: 999;
    display: none;
  }
  
  .Patent .PatentUpload .PatentUploadContext{
    min-width: 200px;
    min-height: 400px;
    width: 90vw;
    height: 90vh;
    margin-top: 30px;
    border: 2px solid var(--mainWidgetcolor);
    background-color: var(--mainBackgroundcolor);
    padding: 20px;
    border-radius: 20px;
  }
  
  .Patent .ShowIMG{
    position: fixed;
    top: 0; left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: none;
  }
  
  .Patent .ShowIMG .ShowIMGContext{
    min-width: 200px;
    min-height: 400px;
    width: 80vw;
    height: 80vh;
  }
  
  .Patent .ShowIMG .ShowIMGContext i{
    font-size: 36px;
    color: white;
    margin-bottom: 20px;
  }
  
  .Patent .ShowIMG .ShowIMGContext img{
    object-fit: contain;
    width: 80vw;
    height: 80vh;
  }
  
  /* 스와이퍼 불렛 공통설정 */
  .swiper-pagination-bullet{
    width: 24px;
    height: 24px;
    background-color: var(--mainWidgetcolor);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
  
  .swiper-pagination-bullet-active{
    width: 30px;
    height: 30px;
    font-weight: bold;
  }

  @media only screen and (max-width: 380px) {
    .Patent_MB{
        zoom: 0.6;
    }
}

@media only screen and (max-width: 900px) {
    .Patent_PC{
        display: none ;
    }
    .Patent_MB {
        display: flex;
    }
}

@media only screen and (min-width: 901px) and (max-width : 1199px) {
    .Patent_PC{
        display: flex ;
        zoom: 0.7;
    }
    .Patent_MB {
        display: none;
    }
}
  
@media only screen and (min-width: 1200px) {
    .Patent_PC{
        display: flex ;
    }
    .Patent_MB {
        display: none;
    }
}

@media only screen and (min-width: 1921px) and (max-width: 2560px ) {
    .Patent_PC{
        zoom: 1.2;
    }
}

@media only screen and (min-width: 2561px){
    .Patent_PC{
        zoom: 1.4;
    }
}