/* pc */
.News_PC .UploadingBlock{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
  z-index: 1000;
}

.News_PC .Sections{
  padding-top: 50px;
  padding-bottom: 70px;
}

.News_PC .Sections .Title{
  font-size: 84px;
  font-weight: bold;
}

.News_PC .Sections .List{
  max-width: 1100px;
  padding: 0 10px;
  overflow: hidden;
}

.News_PC .Sections .List .NewsListBar{
  padding: 10px;
  border-bottom: 2px solid black;
}

.News_PC .Sections .List .NewsListBar > div{
  pointer-events: none; 
}

.News_PC .Sections .List .NewsListBar > i{
  pointer-events: none; 
}

.News_PC .PostButtonStyle{
  padding: 6px 10px;
  border-radius: 10px;
  background-color: var(--mainWidgetcolor);
}

.News_PC .Sections .List .NewsListContext{
  padding: 10px;
  border-bottom: 2px solid black; 
  display: none;
}

.News_PC .Sections .List .NewsListContext img{
  padding: 4px;
  border: 1px solid black; 
  height: 150px;
  object-fit: cover;
}

 /* mb */
 .News_MB{
    padding: 0 30px;
  }

.News_MB .Sections{
  padding-top: 50px;
  padding-bottom: 70px;
}

.News_MB .Sections .Title{
  font-size: 64px;
  font-weight: bold;
}

.News_MB .Sections .NewsListBar { 
  padding: 10px;
  border-bottom: 2px solid var(--mainFontColor1);
}

.News_MB .Sections .NewsListBar button{ 
  padding: 5px;
  border-radius: 5px;
  background-color: var(--mainFontColor2);
  color : var(--mainFontColor3);
}

.News_MB .Sections .NewsListBar button i{ 
  pointer-events: none;
}

.News_MB .Sections .NewsListBar > div,
.News_MB .Sections .NewsListBar > i {
  pointer-events: none;
}

.News_MB .Sections .NewsListContext {
  padding: 20px 10px;
  border-bottom: 2px solid var(--mainFontColor1);
  display: none;
}

.News .NewsUpload{
  position: fixed;
  top: 0; left: 0;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: 999;
  display: none;
}

.News .NewsUpload .NewsUploadContext{
  min-width: 200px;
  min-height: 400px;
  width: 90vw;
  height: 90vh;
  margin-top: 30px;
  border: 2px solid var(--mainWidgetcolor);
  background-color: var(--mainBackgroundcolor);
  padding: 20px;
  border-radius: 20px;
}

.News .ShowIMG{
  position: fixed;
  top: 0; left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: none;
}

.News .ShowIMG .ShowIMGContext{
  min-width: 200px;
  min-height: 400px;
  width: 80vw;
  height: 80vh;
}

.News .ShowIMG .ShowIMGContext i{
  font-size: 36px;
  color: white;
  margin-bottom: 20px;
}

.News .ShowIMG .ShowIMGContext img{
  object-fit: contain;
  width: 80vh;
  height: 80vh;

}

/* 스와이퍼 불렛 공통설정 */
.swiper-pagination-bullet{
  width: 24px;
  height: 24px;
  background-color: var(--mainWidgetcolor);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.swiper-pagination-bullet-active{
  width: 30px;
  height: 30px;
  font-weight: bold;
}

@media only screen and (max-width: 380px) {
  .News_MB{
      zoom: 0.6;
  }
}

@media only screen and (max-width: 900px) {
  .News_PC{
      display: none ;
  }
  .News_MB {
      display: flex;
  }
}

@media only screen and (min-width: 901px) and (max-width : 1199px) {
  .News_PC{
      display: flex ;
      zoom: 0.7;
  }
  .News_MB {
      display: none;
  }
}

@media only screen and (min-width: 1200px) {
  .News_PC{
      display: flex ;
  }
  .News_MB {
      display: none;
  }
}

@media only screen and (min-width: 1921px) and (max-width: 2560px ) {
  .News_PC{
      zoom: 1.2;
  }
}

@media only screen and (min-width: 2561px){
  .News_PC{
      zoom: 1.4;
  }
}