:root{
  --mainBackgroundcolor : #ffffff;
  --subBackgroundcolor : #f5f5f5;
  --mainWidgetcolor : #0C6EB8;
  --mainWidgetcolor2 : #1668A4;
  --mainWidgetcolor3 : #A0CBFF;
  --mainWidgetcolor4 : #3588ee;
  --mainFontColor1 : #373737;
  --mainFontColor2 : #949494;
  --mainFontColor3 : #ffffff;
}

html, body{
  margin: 0;
  padding : 0;
  background-color: var(--mainBackgroundcolor);
  color : var(--mainFontColor1);
  position: relative;
  box-sizing: border-box;
  font-family : 'ONE-Mobile-Title';
}

ul, li {
  margin: 0; padding: 0; list-style: none;
}

a{
  color: inherit; text-decoration: none;
}

.con {
  max-width: 1200px;
}

.conhalf {
  max-width: 500px;
}

.Top-Menu-Fix {
  width: 100%;
  height: auto;
  position: fixed;
  top : 0;
  z-index: 999;
}

.Top-Menu-NonFix {
  width: 100%;
  height: auto;
  position: sticky;
  top : 0;
  z-index: 999;
}

.UploadingBlock{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
  z-index: 1000;
}

/* Button CSS List */
.AddrSearchButton{
  border-radius: 20px;
  background-color: var( --mainWidgetcolor);
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
}

.AddrSearchButton2{
  border-radius: 30px;
  background-color: var( --mainWidgetcolor);
  color: white;
  padding: 10px 16px;
  font-size: 16px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
}

.AddrSearchButton3{
  font-size: 14px;
  font-weight: bold;
  border-radius: 20px;
  border: 4px solid var( --mainWidgetcolor);
  background-color: white;
  padding: 6px 20px;
  margin: 0 0 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.AddrSearchButton3 > * {
  pointer-events: none;
}

.AddrSearchButton4{
  font-size: 14px;
  font-weight: bold;
  border-radius: 20px;
  border: 4px solid var( --mainWidgetcolor);
  background-color: white;
  padding: 6px 20px;
  margin: 0 0 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AddrSearchButton5{
  border-radius: 30px;
  background-color: var( --mainWidgetcolor);
  padding: 16px 0;
  font-size: 14px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.AddrSearchButton5 > * {
  pointer-events: none;
}
.AddrSearchButton6{
  font-size: 14px;
  font-weight: bold;
  border-radius: 10px;
  border: 2px solid var( --mainWidgetcolor);
  background-color: white;
  padding: 6px 20px;
  margin: 0 0 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Pin Style */
.PinBasic{
  border : 1px solid var(--mainFontColor2);
  color: var(--mainFontColor2);
  padding: 10px 10px;
  border-radius: 10px;
}

.PinBasic:hover{
  border-color : var(--mainFontColor1);
  color: var(--mainFontColor1);
}

.PinBlue{
  border : 1px solid var(--mainWidgetcolor);
  color: var(--mainWidgetcolor);
  padding: 10px 8px;
  border-radius: 20px;
  opacity: 80%;
}

.PinBasic:hover{
  opacity: 100%;
}

.PinWhite{
  border : 1px solid rgba(255,255,255,0.5);
  color: rgba(255,255,255,0.5);
  padding: 10px 10px;
  border-radius: 10px;
}

.PinWhite:hover{
  border-color : var(--mainFontColor3);
  color: var(--mainFontColor3);
}

.PinGray{
  border : 2px solid var(--mainFontColor2);
  color: var(--mainFontColor2);
  padding: 10px 20px;
  border-radius: 30px;
}

.PinGray:hover{
  border-color : var(--mainFontColor1);
  color: var(--mainFontColor1);
}

.PinUnderline{
  color: var(--mainWidgetcolor);
  position: relative;
  opacity: 70%;
}

/* .PinUnderline::after{
  content: "";
  position: absolute;
  top: 100%;
  left: -10%;
  width: 120%;
  height: 2px;
  background-color: var(--mainWidgetcolor);
} */

.PinUnderline:hover{
  opacity: 100%;
}