/* pc */
.Posting .UploadingBlock{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
  z-index: 999;
}

.Posting .Section .Title{
  font-size: 32px;
  font-weight: bold;
  margin: 40px 0 30px 0;
}

.Posting .Section .SubTitle{
  font-size: 28px;
  font-weight: bold;
}

.Posting .Section .Sub2ndTitle{
  font-size: 24px;
  font-weight: bold;
  padding: 10px 0 20px 0;
}

.Posting .Section .Contexts{
  font-size: 24px;
  margin-bottom: 30px;
}

.Posting .Section .ManageGrid {
  border-bottom: 4px solid var(--mainWidgetcolor);
}

.Posting .Section .ManageNode {
  padding: 20px;
  border-bottom: 2px solid var(--mainWidgetcolor);
  cursor: pointer;
}

.Posting .Section .ManageNode > * {
  pointer-events: none;
}

.Posting .Section .ManageContents {
  display: none;
  padding: 20px 20px 40px 20px;
}

.Posting .Section .ManageContentsNode {
  margin: 0 0 60px 0;
}

.Posting .Section .ManageContentsNode i{
  position: relative;
}

.Posting .Section .ManageContentsNode i .ManageContentsNodeIMG{
  position: absolute;
  top: 40px; right: 40px;
  width: 200px;
  height: 200px;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid var(--mainWidgetcolor);
  background-color: var(--mainBackgroundcolor);
  z-index: 998;
  display: none;
}

.Posting .Section .ManageContentsNode i:hover .ManageContentsNodeIMG{
  display: flex;
}

.ChagePW, .QuitAcc{
  position: fixed;
  top: 0; left: 0;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: 999;
  display: none;
}

.ChagePW .ChagePWContext, .QuitAcc .ChagePWContext{
  min-width: 200px;
  min-height: 400px;
  width: 60vw;
  height: 60vh;
  margin-top: 30px;
  border: 2px solid var(--mainWidgetcolor);
  background-color: var(--mainBackgroundcolor);
  padding: 20px;
  border-radius: 20px;
}



.Posting_MB {
  height: 80vh;
}

@media only screen and (max-width: 380px) {
  .Posting_MB{
      zoom: 0.6;
  }
}

@media only screen and (max-width: 900px) {
  .Posting{
      display: none ;
  }
  .Posting_MB {
      display: flex;
  }
}

@media only screen and (min-width: 901px) and (max-width : 1199px) {
  .Posting{
      display: flex ;
      zoom: 0.7;
  }
  .Posting_MB {
      display: none;
  }
}

@media only screen and (min-width: 1200px) {
  .Posting{
      display: flex ;
  }
  .Posting_MB {
      display: none;
  }
}

@media only screen and (min-width: 1921px) and (max-width: 2560px ) {
  .Posting{
      zoom: 1.2;
  }
}

@media only screen and (min-width: 2561px){
  .Posting{
      zoom: 1.4;
  }
}