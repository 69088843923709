.AboutUs{
    font-family : 'ONE-Mobile-Title';
    position: relative;
    /* color: var(--mainWidgetcolor); */
}

.AboutUs .Title{
    color: var(--mainWidgetcolor);
}

.AboutUs_PC .Title{
    font-size: 50px;
}

/* pc */
/* ci */
.AboutUs_PC-Banner{
    height: 400px;
    border-bottom: 1px solid var(--mainFontColor1);
    position: relative;
}

.AboutUs_PC-Banner .AboutUs_PC-Banner-Text, .AboutUs_PC-Banner .AboutUs_PC-banner-menu{
    font-size: 24px;
    z-index: 997;
    color: white;
    padding: 0 60px;
}

.AboutUs_PC-Banner .Title{
    color: white;
    font-size: 48px;
    font-weight: bold;
}

.AboutUs_PC-Banner .BannerBackColor{
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 996;
    background-color: rgba(0,0,0,0.3);
}

.AboutUs_PC-Banner .BannerBackIMG{
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 995;
}

.AboutUs_PC-Banner .BannerBackIMG img{
    object-fit: cover;
    object-position: center;
}

.AboutUs_PC-sub-menu{
    position: sticky;
    top: 80px;
    left: 0;
    right: 0;
    background-color: var(--subBackgroundcolor);
    color : var(--mainFontColor2);
    z-index: 997;
}

.AboutUs_PC-sub-menu > ul {
    font-size: 16px;
    height: 50px;
    width: 1200px;
    /* padding: 0 30px 0 0; */
}

.AboutUs_PC-sub-menu > ul li {
    width: 100px;
}

.AboutUs_PC-banner-menu > ul {
    font-size: 18px;
    width: 400px;
}

.AboutUs_PC-CI, .AboutUs_PC-History, .AboutUs_PC-Ogz, .AboutUs_PC-Customer{
    font-size: 36px;
    font-weight: bold;
}
/* ci */
.AboutUs_PC-CI{
    margin: 40px 0 80px 0;
}

.AboutUs_PC-CI .Text-Left{
    font-size: 40px;
    font-weight: 800;
    color: var(--mainWidgetcolor);
}

.AboutUs_PC-CI .Text-Right{
    font-size: 26px;
    font-weight: 100;
    margin-top: 10px;
    color: var(--mainWidgetcolor2);
}

.AboutUs_PC-CI .IntroBox{
    margin-top : 100px;
}

.AboutUs_PC-CI .IntroBox .IntroBoxList{
    height: 400px;
    font-size: 20px;
    font-weight: normal;
    background-color: var(--subBackgroundcolor);
    position: relative;
    overflow: hidden;
}

.AboutUs_PC-CI .IntroBox .IntroBoxList > img{
    object-fit: cover;
    object-position: -55px center;
    background-color: var(--mainBackgroundcolor);
}

.AboutUs_PC-CI .IntroBox .IntroBoxList .IntroBoxListText{
    position: absolute;
    bottom: 0; left: 0; right: 0;
    padding: 20px 20px 10px 20px;
    background-color: rgba(0,0,0,0.5);
    font-size: 18px;
    color: white;
}

/* history */
.AboutUs_PC-History {
    margin: 0 0 60px 0;
}
.AboutUs_PC-History .Years{
    padding : 30px 50px 0 0;
}
.AboutUs_PC-History .Years > div{
    position: sticky;
    top : 180px;
}

.AboutUs_PC-History .Months{
    padding : 20px 0 20px 40px;
    font-size : 22px;
    font-weight : normal;
    color: var(--mainFontColor2);
    border-left-width : 2px;
    border-left-color : var(--mainFontColor2);
}

.AboutUs_PC-History .Months.Sticky{
    color: var(--mainFontcolor);
    border-left-width : 4px;
}

.AboutUs_PC-History .Months > div:first-child{
    font-size : 28px;
    font-weight : bold;
    width : 160px;
}
/* ogz */
.AboutUs_PC-Ogz .Pictures1 img {
   /* height: 500px; */
}

/* customer */
.AboutUs_PC-Customer-lists{
    font-size: 14px;
    font-weight: lighter;
    border: 1px solid ;
    border-color: var(--mainFontcolor2);
    border-radius: 10px;
    padding: 10px;
    width: 280px;
    margin-right: 20px;
}

.AboutUs_PC-Customer-lists > div > img{
    height: 80px;
    margin: 10px 0;
    object-fit: contain;
    background-color: var(--mainBackgroundcolor);
}
/* mobile */
.AboutUs_MB-Banner{
    height: 300px;
    border-bottom: 1px solid var(--mainFontColor1);
    position: relative;
}

.AboutUs_MB-Banner .AboutUs_MB-Banner-Text, .AboutUs_MB-Banner .AboutUs_MB-banner-menu{
    font-size: 18px;
    z-index: 997;
    color: white;
    padding: 0 30px;
}

.AboutUs_MB-Banner .Title{
    font-size: 36px;
    font-weight: bold;
    color: white;
}

.AboutUs_MB-Banner .BannerBackColor{
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 996;
    background-color: rgba(0,0,0,0.3);
}

.AboutUs_MB-Banner .BannerBackIMG{
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 995;
}

.AboutUs_MB-Banner .BannerBackIMG img{
    object-fit: cover;
    object-position: center;
}

.AboutUs_MB-sub-menu{
    position: sticky;
    top: 80px;
    left: 0;
    right: 0;
    z-index: 998;
}

.AboutUs_MB-sub-menu > ul {
    font-size: 14px;
    height: 40px;
    padding: 0 30px;
    background-color: var(--subBackgroundcolor);
    color : var(--mainFontColor2);
}

.AboutUs_MB-banner-top{
    font-size: 36px;
    font-weight: bold;
}

.AboutUs_MB-banner-menu > ul {
    font-size: 14px;
}

.AboutUs_MB-CI, .AboutUs_MB-History, .AboutUs_MB-Ogz, .AboutUs_MB-Customer{
    font-size: 32px;
    font-weight: bold;
    padding: 0 30px;
}
/* ci */
.AboutUs_MB-CI{
    margin:  0 40px 0;
}

.AboutUs_MB-CI .Text-Left{
    font-size: 32px;
    font-weight: 800;
    margin: 0 0 10px 0;
    color: var(--mainWidgetcolor);
}

.AboutUs_MB-CI .Text-Right{
    font-size: 18px;
    font-weight: 100;
    margin-top: 10px;
    color: var(--mainWidgetcolor2);
}

.AboutUs_MB-CI .IntroBox{
    margin-top : 30px;
}

.AboutUs_MB-CI .IntroBox .IntroBoxList{
    height: 200px;
    font-size: 20px;
    font-weight: normal;
    background-color: var(--subBackgroundcolor);
    position: relative;
    overflow: hidden;
}

.AboutUs_MB-CI .IntroBox .IntroBoxList > img{
    object-fit: contain;
    object-position: center -50px;
    background-color: black;
}

.AboutUs_MB-CI .IntroBox .IntroBoxList .IntroBoxListText{
    position: absolute;
    bottom: 0; left: 0; right: 0;
    padding: 20px 20px 10px 20px;  
    background-color: rgba(0,0,0,0.5);
    font-size: 18px;
    color: white;
}

/* history */
.AboutUs_MB-History .Years > div{
    position: sticky;
    top : 150px;
    font-size: 20px;
}

.AboutUs_MB-History .Months{
    font-size : 16px;
    font-weight : normal;
    padding: 10px 0 10px 14px;
    color: var(--mainFontColor2);
    border-left-width : 2px;
    border-left-color : var(--mainFontColor2);
}

.AboutUs_MB-History .Months.Sticky{
    color: var(--mainFontcolor);
    border-left-width : 4px;
}

.AboutUs_MB-History .Months > div:first-child{
    font-size : 20px;
    font-weight : bold;
}
/* ogz */
.AboutUs_MB-Ogz img {
   object-fit: contain;
}
/* customer */
.AboutUs_MB-Customer-lists{
    font-size: 12px;
    font-weight: lighter;
    border: 1px solid ;
    border-color: var(--mainFontcolor2);
    border-radius: 10px;
    padding: 10px;
}

.AboutUs_MB-Customer-lists > div > img{
    width: 80%;
    height: 70px;
    object-fit: contain;
    background-color: var(--mainBackgroundcolor);
}

@media only screen and (max-width: 380px) {
    .AboutUs_MB{
        zoom: 0.6;
    }

    .AboutUs_MB-CI .IntroBox .IntroBoxList{
        height: 300px;
    }

    .AboutUs_MB-CI .IntroBox .IntroBoxList > img{
        object-position: center -90px;
    }
}

@media only screen and (max-width: 900px) {
    .AboutUs_PC{
        display: none ;
    }
    .AboutUs_MB {
        display: flex;
    }
}

@media only screen and (min-width: 901px) and (max-width : 1199px) {
    .AboutUs_PC{
        display: flex ;
        zoom: 0.7;
    }
    .AboutUs_MB {
        display: none;
    }
}
  
@media only screen and (min-width: 1200px) {
    .AboutUs_PC{
        display: flex ;
    }
    .AboutUs_MB {
        display: none;
    }
}

@media only screen and (min-width: 1921px) and (max-width: 2560px ) {
    .AboutUs_PC{
        zoom: 1.2;
    }
}

@media only screen and (min-width: 2561px){
    .AboutUs_PC{
        zoom: 1.4;
    }
}