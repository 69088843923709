.Product{
    font-family : 'ONE-Mobile-Title';
}

.Product img{
    z-index: 998;
}

/* pc */
.Product_PC-Banner{
    height: 400px;
    border-bottom: 1px solid var(--mainFontColor1);
    position: relative;
}

.Product_PC-Banner p{
    font-size: 24px;
    z-index: 997;
    color: white;
}

.Product_PC-Banner .Title{
    font-size: 48px;
    color: white;
}

.Product_PC-Banner .BannerBackColor{
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 996;
    background-color: rgba(0,0,0,0.5);
}

.Product_PC-Banner .BannerBackIMG{
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 995;
}

.Product_PC-Banner .BannerBackIMG img{
    object-fit: cover;
    object-position: center;
}

.Product_PC-products{
    font-size: 32px;
    font-weight: normal;
    border-bottom: 1px solid var(--mainFontColor1);
}

.Product_PC-products .MenuAreaList > ul > li{
    font-size: 24px;
    border-right: 1px solid var(--mainFontColor1);
    border-left: 1px solid var(--mainFontColor1);
    height: 80px;
}

.Product_PC-products .MenuAreaList > ul > li:first-child{
    border-left-width: 0px;
}

.Product_PC-products .MenuAreaList > ul > li:last-child{
    border-right-width: 0px;
}

/* MB */
.Product_MB-Banner{
    height: 240px;
    border-bottom: 1px solid var(--mainFontColor1);
    position: relative;
}

.Product_MB-Banner p{
    font-size: 18px;
    z-index: 997;
    color: white;
}

.Product_MB-Banner .Title{
    font-size: 36px;
    color: white;
}

.Product_MB-Banner .BannerBackColor{
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 996;
    background-color: rgba(0,0,0,0.5);
}

.Product_MB-Banner .BannerBackIMG{
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 995;
}

.Product_MB-Banner .BannerBackIMG img{
    object-fit: cover;
    object-position: center;
}

.Product_MB-products{
    font-size: 32px;
    font-weight: normal;
    border-bottom: 1px solid var(--mainFontColor1);
}

.Product_MB-products .MenuAreaList > ul > li{
    font-size: 14px;
    border-right: 1px solid var(--mainFontColor1);
    border-left: 1px solid var(--mainFontColor1);
    height: 60px;
}

.Product_MB-products .MenuAreaList > ul > li:first-child{
    border-left-width: 0px;
}

.Product_MB-products .MenuAreaList > ul > li:last-child{
    border-right-width: 0px;
}

@media only screen and (max-width: 380px) {
    .Product_MB{
        /* zoom: 0.6; */
    }
}

@media only screen and (max-width: 900px) {
    .Product_PC{
        display: none ;
    }
    .Product_MB {
        display: flex;
    }
}

@media only screen and (min-width: 901px) and (max-width : 1199px) {
    .Product_PC{
        display: flex ;
        zoom: 0.7;
    }
    .Product_MB {
        display: none;
    }
}
  
@media only screen and (min-width: 1200px) {
    .Product_PC{
        display: flex ;
    }
    .Product_MB {
        display: none;
    }
}

@media only screen and (min-width: 1921px) and (max-width: 2560px ) {
    .Product_PC{
        zoom: 1.2;
    }
}

@media only screen and (min-width: 2561px){
    .Product_PC{
        zoom: 1.4;
    }
}