.Login .LoginBox{
  min-height: 100vh;
  background-color: var(--mainbanner-active);
}

.Login .LoginBox .Title {
  padding-left: 20px;
  margin-bottom: 20px;
  font-size: 32px;
  font-weight: bold;
}

.Login form > div  > input{
  border: 1px solid black;
  padding: 14px 20px;
  border-radius: 14px;
  color: black;
}