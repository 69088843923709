.Home_PC .BigName {
    font-size: 42px;
    color: var(--mainWidgetcolor);
}

.Home_MB .BigName {
    font-size: 32px;
    color: var(--mainWidgetcolor);
}

/* pc */
/* pc-top */
.Home_PC-top {
    /* height: calc( ( 100vw - 15px ) / 16 * 9);    */
    /* 15px는 브라우저 스크롤바 폭임(크롬기준) */
    height: 100vh;
    width: 100%;
    color: var(--mainFontColor3);
    position: relative;
    overflow: hidden;
    background-color: black;
}

.Home_PC-top .BackIMG{
    position: absolute;
    top: 0; bottom : 0;
    left: auto; right: auto;
}

.Home_PC-top .BackIMG::after{
    position: absolute;
    top: 0; bottom: 0; left: 0; right: 0;
    content: "";
    background-color: rgba(0,0,0,0.2);
    z-index: 996;
}
.Home_PC-top .Context{
    z-index: 997;
}

.Home_PC-top .Text{
    text-align: center;
    font-size: 48px;
    font-weight: 800;
    margin-bottom: 20px;
}

.Home_PC-top p{
    text-align: center;
    font-size: 22px;
    padding: 10px 0 0 0;
}

/* Intro */
.Home_PC-intro{
    margin: 140px 0 140px 0;
}

.Home_PC-intro .Text-Left{
    font-size: 44px;
    font-weight: 800;
    color: var(--mainWidgetcolor);
}

.Home_PC-intro .Text-Right{
    font-size: 28px;
    font-weight: 100;
    margin-top: 10px;
    color: var(--mainWidgetcolor2);
}

.Home_PC-intro .IntroBox{
    margin-top : 100px;
}

.Home_PC-intro .IntroBox .IntroBoxList{
    height: 400px;
    font-size: 20px;
    font-weight: normal;
    background-color: var(--subBackgroundcolor);
    position: relative;
    overflow: hidden;
}

.Home_PC-intro .IntroBox .IntroBoxList > img{
    object-fit: cover;
    object-position: -55px center;
    background-color: var(--mainBackgroundcolor);
}

.Home_PC-intro .IntroBox .IntroBoxList .IntroBoxListText{
    position: absolute;
    bottom: 0; left: 0; right: 0;
    padding: 20px 20px 10px 20px;
    background-color: rgba(0,0,0,0.5);
    font-size: 18px;
    color: white;
}

.Home_PC-Trade, .Home_PC-News{
    padding: 120px 0 80px 0;
    color : var(--mainWidgetcolor);
}

/* Trade */
.Home_PC-Trade .Contents{
    margin: 40px 40px 0 40px;
}

.Home_PC-Trade .companyList{
    max-width: 1120px;
}

.Home_PC-Trade .companyLists img{
    object-fit: contain;
}

/* news */
.Home_PC-News .Contents {
    margin: 30px 60px 0 60px;
}

.Home_PC-News .HeadLines {
    border-bottom: 1px solid var(--mainWidgetcolor);
    padding: 30px 20px;
}

.Home_PC-News .NContext{
    height: 0;
    padding: 0 40px;
    overflow: hidden;
    overflow-y: auto;
    
    border-bottom: 1px solid var(--mainWidgetcolor);
    transition: height 1s, padding 1s;
}

.Home_PC-News .NContext.active{
    height: 200px;
    padding: 30px 40px;
}

.Home_PC-News a{
    font-size: 18px;
    font-weight: 500;
}

/* mb */
/* mb-top */
.Home_MB-intro, .Home_MB-Trade, .Home_MB-News{
    padding: 0 30px;
}

.Home_MB-top {
    height: 100vh;
    color: var(--mainFontColor3);
    position: relative;
    background-color: black;
}

.Home_MB-top .BackIMG{
    position: absolute;
    top: 0; bottom: 0;
    left: auto; right: auto;
}

.Home_MB-top .BackIMG::after{
    position: absolute;
    top: 0; bottom: 0; left: 0; right: 0;
    content: "";
    background-color: rgba(0,0,0,0.2);
    z-index: 996;
}

.Home_MB-top .BackIMG img{
    object-fit: cover;
}

.Home_MB-top .Context{
    z-index: 997;
}

.Home_MB-top .Text{
    text-align: center;
    font-size: 32px;
    font-weight: 800;
    margin-bottom: 20px;
}

.Home_MB-top p{
    text-align: center;
    font-size: 22px;
    padding: 10px 0 0 0;
}

/* Intro */
.Home_MB-intro{
    margin: 20px 0 100px 0;
}

.Home_MB-intro .Text-Left{
    font-size: 32px;
    font-weight: 800;
    margin: 0 0 10px 0;
    color: var(--mainWidgetcolor);
}

.Home_MB-intro .Text-Right{
    font-size: 18px;
    font-weight: 100;
    margin-top: 10px;
    color: var(--mainWidgetcolor2);
}

.Home_MB-intro .IntroBox{
    margin-top : 30px;
}

.Home_MB-intro .IntroBox .IntroBoxList{
    height: 200px;
    font-size: 20px;
    font-weight: normal;
    background-color: var(--subBackgroundcolor);
    position: relative;
    overflow: hidden;
}

.Home_MB-intro .IntroBox .IntroBoxList > img{
    object-fit: cover;
    object-position: center -50px;
    background-color: var(--mainBackgroundcolor);
}

.Home_MB-intro .IntroBox .IntroBoxList .IntroBoxListText{
    position: absolute;
    bottom: 0; left: 0; right: 0;
    padding: 20px 20px 10px 20px;
    background-color: rgba(0,0,0,0.5);
    font-size: 18px;
    color: white;
}

.Home_MB-Trade, .Home_MB-News{
    color: var(--mainWidgetcolor);
    padding: 80px 30px 20px 30px;
}

/* Trade */
.Home_MB-Trade .companyLists img{
    object-fit: contain;
}

/* news */
.Home_MB-News{
    font-size: 14px;
}

.Home_MB-News .HeadLines {
    border-bottom: 1px solid var(--mainWidgetcolor);
    padding: 20px 10px;
}

.Home_MB-News .NContext{
    display: none;
    padding: 30px 40px;
    overflow: hidden;
    overflow-y: auto;
    border-bottom: 1px solid var(--mainWidgetcolor);
}

.Home_MB-News .NContext.active{
    display: flex;
}

.Home_MB-News a{
    font-size: 18px;
    font-weight: 500;
}

@media only screen and (max-width: 380px) {
    .Home_MB{
        zoom: 0.6;
    }

    .Home_MB-top {
        height: calc(100vh * 10 / 6);
    }
    
    .Home_MB-intro .IntroBox .IntroBoxList{
        height: 300px;
    }

    .Home_MB-intro .IntroBox .IntroBoxList > img{
        object-position: center -90px;
    }
}

@media only screen and (max-width: 900px) {
    .Home_PC{
        display: none ;
    }
    .Home_MB {
        display: flex;
    }
}

@media only screen and (min-width: 901px) and (max-width : 1199px) {
    .Home_PC{
        display: flex ;
        zoom: 0.7;
    }
    .Home_MB {
        display: none;
    }

    .Home_PC-top {
        height: calc(( 100vw - 15px ) / 16 * 9 / 7 * 10);
    }
}
  
@media only screen and (min-width: 1200px) {
    .Home_PC{
        display: flex ;
    }
    .Home_MB {
        display: none;
    }
}

@media only screen and (min-width: 1921px) and (max-width: 2560px ) {
    .Home_PC{
        zoom: 1.2;
    }

    .Home_PC-top {
        height: calc(( 100vw - 15px ) / 16 * 9 / 12 * 10);
    }
}

@media only screen and (min-width: 2561px){
    .Home_PC{
        zoom: 1.4;
    }

    .Home_PC-top {
        height: calc(( 100vw - 15px ) / 16 * 9 / 14 * 10);
    }
}