/* pc */
.Join .UploadingBlock{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
  z-index: 999;
}

.Join .Main-Banner .Title{
  padding-left: 20px;
  margin-bottom: 20px;
  font-size: 32px;
  font-weight: bold;
}

.Join .Main-Banner{
  min-height: 100vh;
  background-color: var(--mainbanner-active);
}

.Join .Main-Banner form > div  > input{
  border: 1px solid black;
  padding: 14px 20px;
  border-radius: 14px;
}