/* pc */
/* ci */
.Location_PC-Banner{
    height: 400px;
    border-bottom: 1px solid var(--mainFontColor1);
    position: relative;
}

.Location_PC-Banner .Location_PC-Banner-Text{
    font-size: 24px;
    z-index: 997;
    color: white;
    padding: 100px 60px;
}

.Location_PC-Banner .Title{
    color: white;
    font-size: 48px;
    font-weight: bold;
}

.Location_PC-Banner .BannerBackColor{
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 996;
    background-color: rgba(0,0,0,0.3);
}

.Location_PC-Banner .BannerBackIMG{
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 995;
}

.Location_PC-Banner .BannerBackIMG img{
    object-fit: cover;
    object-position: center;
}

/* ci */
.Location_PC-CI{
    font-size: 36px;
    font-weight: bold;
    padding : 20px;
}

.Location_PC-CI-lists span{
    font-size: 20px;
    font-weight: bold;
}

.Location_PC-CI-lists p{
    font-size: 20px;
    font-weight: normal;
}

.Location_PC-CI-lists img{
    margin-bottom: 20px;
    height: 400px;
    width: 800px;
    object-fit: cover;
    filter: grayscale(0);
}

/* mobile */
.Location_MB-Banner{
    height: 300px;
    border-bottom: 1px solid var(--mainFontColor1);
    position: relative;
}

.Location_MB-Banner .Location_MB-Banner-Text{
    font-size: 18px;
    z-index: 997;
    color: white;
    padding: 0 30px;
}

.Location_MB-Banner .Title{
    font-size: 36px;
    font-weight: bold;
    color: white;
}

.Location_MB-Banner .BannerBackColor{
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 996;
    background-color: rgba(0,0,0,0.3);
}

.Location_MB-Banner .BannerBackIMG{
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 995;
}

.Location_MB-Banner .BannerBackIMG img{
    object-fit: cover;
    object-position: center;
}
/* ci */
.Location_MB-CI{
    font-size: 32px;
    font-weight: bold;
    padding: 0 30px;
}

.Location_MB-CI-lists{
    font-size: 24px;
    padding: 20px 0 0 0;
}

.Location_MB-CI-lists span{
    font-size: 14px;
    font-weight: bold;
}

.Location_MB-CI-lists p{
    font-size: 14px;
    font-weight: normal;
}

.Location_MB-CI-lists img{
    margin-bottom: 20px;
    height: 200px;
    max-width: 400px;
    object-fit: cover;
    filter: grayscale(0);
}

@media only screen and (max-width: 380px) {
    .Location_MB{
        /* zoom: 0.6; */
    }
}

@media only screen and (max-width: 900px) {
    .Location_PC{
        display: none ;
    }
    .Location_MB {
        display: flex;
    }
}

@media only screen and (min-width: 901px) and (max-width : 1199px) {
    .Location_PC{
        display: flex ;
        zoom: 0.7;
    }
    .Location_MB {
        display: none;
    }
}
  
@media only screen and (min-width: 1200px) {
    .Location_PC{
        display: flex ;
    }
    .Location_MB {
        display: none;
    }
}

@media only screen and (min-width: 1921px) and (max-width: 2560px ) {
    .Location_PC{
        zoom: 1.2;
    }
}

@media only screen and (min-width: 2561px){
    .Location_PC{
        zoom: 1.4;
    }
}