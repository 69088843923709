/* pc  */
.ProcessDetail_PC .Middles{
    font-size: 36px;
}

.ProcessDetail_PC .Middles > p{
    position: relative;
}

.ProcessDetail_PC .Middles > p .PinBlue{
    position: absolute;
    top: -20%; left: 105%;
    width: 90px;
    font-size: 12px;
    border-width: 2px;
    border-radius: 30px;
    padding: 5px;
}

.ProcessDetail_PC .Description{
    font-size: 20px;
    padding: 0 0 0 10px;
}

/* icons */
.ProcessDetail_PC .ProcessIcons > div{
    padding: 10px;
    background-color: var(--mainWidgetcolor);
    border-radius: 100%;
    overflow: hidden;
}

.ProcessDetail_PC .ProcessIcons p{
    color: var(--mainWidgetcolor);
}

.ProcessDetail_PC .ProcessIcons img{
    width: 80px;
    position: relative;
}

/* swiper */
.ProcessDetail_PC .mySwiper{
    width: 550px;
}

.ProcessDetail_PC .ProductLists{
    overflow: hidden;
    position: relative;
    z-index: 995;
    border: 2px solid rgba(0,0,0,0.5);
}

.ProcessDetail_PC .ProductLists img{
    z-index: 995;
}

.ProcessDetail_PC .ProductListsName{
    position: absolute;
    bottom: 0; left: 0; right: 0; height: 45px;
    background-color: rgba(0,0,0,0.6);
    z-index: 996;
    color: white;
}

.ProcessDetail_PC .ListsHeader{
    font-size: 18px;
    padding: 8px 10px;
    border-bottom: 2px solid black;
    border-top: 2px solid black;
}

.ProcessDetail_PC .Lists{
    font-size: 18px;
    padding: 20px 10px;
    border-bottom: 1px solid black;
}

.ProcessDetail_PC .swiper-pagination-bullet{
    width: 10px; height: 10px;
}

/* 이미지 */
.ProcessDetail_PC .ProcessIMG{
    border-radius: 40px;
}

/* mb */
.ProcessDetail_MB{
    padding: 0 30px;
}

.ProcessDetail_MB .Middles{
    font-size: 32px;
}

.ProcessDetail_MB .Middles > p{
    position: relative;
}

.ProcessDetail_MB .Middles > p .PinBlue{
    position: absolute;
    top: -20%; left: 105%;
    width: 90px;
    font-size: 12px;
    border-width: 2px;
    border-radius: 30px;
    padding: 5px;
}

.ProcessDetail_MB .Description{
    font-size: 16px;
}

/* icons */
.ProcessDetail_MB .ProcessIcons > div{
    padding: 10px;
    background-color: var(--mainWidgetcolor);
    border-radius: 100%;
    overflow: hidden;
}

.ProcessDetail_MB .ProcessIcons p{
    color: var(--mainWidgetcolor);
}

.ProcessDetail_MB .ProcessIcons img{
    width: 50px;
    position: relative;
}

/* processimg */
.ProcessDetail_MB .ProcessIMG{
    border-top: 4px solid var(--mainWidgetcolor);
    padding-top: 15px;
    
}
/* swiper */
.ProcessDetail_MB .mySwiper{
    width : 80vw;
}

.ProcessDetail_MB .ProductLists{
    overflow: hidden;
    border: 2px solid rgba(0,0,0,0.5);
}

.ProcessDetail_MB .ProductLists img{
    z-index: 995;
}

.ProcessDetail_MB .ProductListsName{
    position: absolute;
    bottom: 0; left: 0; right: 0; height: 45px;
    background-color: rgba(0,0,0,0.6);
    z-index: 996;
    color: white;
}

.ProcessDetail_MB .ListsHeader{
    font-size: 18px;
    padding: 8px 10px;
    border-bottom: 2px solid black;
    border-top: 2px solid black;
}

.ProcessDetail_MB .Lists{
    font-size: 18px;
    padding: 20px 10px;
    border-bottom: 1px solid black;
}

.ProcessDetail_MB .swiper-pagination-bullet{
    width: 10px; height: 10px;
}

@media only screen and (max-width: 900px) {
    .ProcessDetail_PC{
        display: none ;
    }
    .ProcessDetail_MB {
        display: flex;
    }
}
  
@media only screen and (min-width: 901px) {
    .ProcessDetail_PC{
        display: flex ;
    }
    .ProcessDetail_MB {
        display: none;
    }
}
